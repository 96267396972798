<template>
  <div class="box">
    <div class="box-displayboard">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="box-title">
        <el-breadcrumb-item
          v-for="(item, index) in breadList"
          :key="index"
          :to="{ path: item.path }"
          style="display: flex; align-items: center"
        >
          <span>{{ item.name }}</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
      <router-view class="box-body" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadList: [], // 路由集合
    };
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    },
  },
  methods: {
    isHome(route) {
      return route.name === 'home';
    },
    getBreadcrumb() {
      let { matched } = this.$route;
      // 如果不是首页
      if (!this.isHome(matched[0])) {
        matched = [{ path: '/console' }].concat(matched);
      }
      this.breadList = matched.splice(2);
    },
  },
  created() {
    this.getBreadcrumb();
  },
};
</script>

<style scoped>
.box {
  display: flex;
  /* justify-content: flex-end;
  align-items: flex-end; */
  height: 100%;
  padding: 0 20px;
  background-color: #f7f9fc;
  position: relative;
}

.box-displayboard {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.box-title {
  width: 100%;
  height: 50px;
  text-align: left;
  display: flex;
  font-size: 14px;
  font-family: PingFang SC Regular;
}

span {
  color: #3d4966;
  font-weight: normal;
}

.box-body {
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 25px;
  margin-bottom: 20px;
}
.box-body >>> .box-top {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 6px 0;
}
.box-body >>> .menu {
  background-color: #ffffff;
  border-bottom: 0px;
}
.box-body >>> .el-menu-item.is-active {
  background-color: #ffffff;
  color: #2285ff;
  font-size: 24px;
  font-family: PingFang SC Medium, PingFang SC Medium-Medium;
  font-weight: 500;
}
.box-body >>> .el-menu.el-menu--horizontal {
  color: #2285ff;
}
.box-body >>> .el-menu-item {
  color: #969696;
  font-size: 24px;
  font-family: PingFang SC Medium, PingFang SC Medium-Medium;
  font-weight: 500;
}

.box-body >>> .el-table td,
.el-table th.is-leaf {
  font-size: 14px;
  font-family: PingFang SC Regular, PingFang SC Regular-Regular;
  font-weight: 400;
  text-align: center;
  color: #1f2e4d;
}
.box-body >>> .el-table--border th,
.el-table__fixed-right-patch {
  background: #f7f9fc;
  font-size: 14px;
  font-family: PingFang SC Regular, PingFang SC Regular-Regular;
  font-weight: 400;
  text-align: center;
  color: #1f2e4d;
}

.box-body >>> .el-button--default {
  width: 120px;
  height: 38px;
  background: #2285ff;
  border-radius: 4px;
  font-size: 16px;
  font-family: PingFang SC Medium, PingFang SC Medium-Medium;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
}

.box-body >>> .el-button--primary {
  width: 120px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #2285ff;
  border-radius: 4px;
  font-size: 16px;
  font-family: PingFang SC Medium, PingFang SC Medium-Medium;
  font-weight: 500;
  text-align: center;
  color: #2285ff;
}
</style>
